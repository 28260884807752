$henley-blue: #405363;
$henley-gray: #75767A;
$secondary-gray: #C1C5C8;
$light-gray: #C5C6C8;
$gray: #6F7072;
$dark-gray: #4B4B4D;
$background-colour: #f7f7f7;

[data-citycountry-embed] {
	.cityTable {
		color: $henley-blue;
		width: 100%;

		tr {
			border-bottom: 1px solid $secondary-gray;
			@media screen and (max-width: 700px) {
			    border: none;
		  	}
		}

		.cityTableHead th {
			font-weight: normal;
			.cityTableHeader {
				margin: 0 0 10px;
				font-size: 36px;
    			line-height: 1.1666666667em;
    			@media screen and (max-width: 700px) {
					font-size: 18px;
	    			line-height: 24px;
				}
			}
			.cityTableHeaderH2 {
				padding-left: 10px;
				margin-bottom: 10px;
			}
			.cityTableHeaderScore {
				font-size: 18px;
				font-weight: normal;
				@media screen and (max-width: 700px) {
				    font-size: 14px;
				    margin-left: -40px;
			  	}
			}
		}

		th.totalScore {
			text-align: right;
		}


		// data table
		td.iconColumn {
			width: 70px;
			padding-right: 30px;
			@media screen and (max-width: 700px) {
			    min-width: 42px;
			    max-width: 42px;
				padding-right: 16px;
			    img {
			  		margin-top: 5px;
			  	}
		  	}
		}

		td.nameColumn {
			width: 180px;
			padding-right: 30px;
			font-size: 18px;
			@media screen and (max-width: 700px) {
			    display: none;
		  	}
		}

		td.barchartColumn {
			position: relative;

			.barchartBackground,
			.barchartForeground {
				position: absolute;
				left: 0;
				top: 19px;
				height: 20px;
				border-radius: 10px;
				background: #fff;

				@media screen and (max-width: 700px) {
				    height: 12px;
				    top: 16px;
			  	}
			}
			.barchartBackground {
				width: 100%;
			}
			@keyframes slidein {
			  from {
			    max-width: 0px;
			    opacity: 0;
			  }
			  to {
			    max-width: 100%;
			    opacity: 1;
			  }
			}
			.barchartForeground {
			    overflow: hidden;
				background: $henley-blue;
				width: 0%;
			    animation: slidein 700ms;
			}
			.barChartLabel {
				margin: 28px 0 0;
				font-size: 14px;
				@media screen and (min-width: 700px) {
				    display: none;
			  	}
				@media screen and (max-width: 700px) {
				    display: block;
			  	}
			}
		}

		@keyframes appear {
		  from { opacity: 0; }
		  to { opacity: 1; }
		}

		td.scoreColumn {
			width: 60px;
			color: $henley-gray;
			text-align: right;
			font-family: Garamond Regular;
    		font-weight: 400;
    		font-size: 26px;
    		line-height: 26px;
    		padding: 16px 0 16px 30px;
    		animation: appear 1s;
    		@media screen and (max-width: 700px) {
    			width: 40px;
    		}
		}

		@media screen and (max-width: 700px) {
		    .cityTableHeaderH3,
			.cityTableHeaderH2,
			td.scoreColumn {
				font-size: 17px;
			}
			td.scoreColumn {
				line-height: 17px;
				padding: 0 0 5px 30px;
			}
	  	}
	}
}
[data-citycountry-embed="kuala-lumpur"] {
	@media screen and (max-width: 700px) {
		.cityTableHead th {
		    .cityTableHeader {
				font-size: 15px !important;
				margin: 0;
			}
		}
  	}

}