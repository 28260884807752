$henley-blue: #405363;
$henley-gray: #75767a;
$secondary-gray: #c1c5c8;
$light-gray: #c5c6c8;
$gray: #6f7072;
$dark-gray: #4b4b4d;
$background-colour: #f7f7f7;

[data-compare-embed] {
  .embedH2 {
    margin-bottom: 50px;
  }
  .compareEmbedContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 900px) {
      display: block;
    }
  }

  .leftColumn {
    min-width: 360px;
    max-width: 22%;
    padding-right: 40px;
    flex-grow: 1;

    .parameterSelection {
      max-width: 325px;
      .topH3 {
        margin-bottom: 5px;
        font-size: 26px;
        @media screen and (max-width: 1400px) {
          font-size: 22px;
        }
      }
      .chevronDown,
      .chevronUp {
        width: 32px;
        height: 32px;
        @media screen and (max-width: 900px) {
          width: 24px;
          height: 24px;
        }
      }

      @media screen and (max-width: 900px) {
        margin-bottom: 30px;
      }
    }

    @media screen and (max-width: 900px) {
      max-width: 100%;
    }
  }
  .rightColumn {
    flex-grow: 2;
  }

  .compareTable {
    color: $henley-blue;
    width: 100%;

    tr {
      border-bottom: 1px solid $secondary-gray;
    }
    td {
      @media screen and (max-width: 1400px) {
        vertical-align: top;
        padding-top: 16px;
      }
      @media screen and (max-width: 700px) {
        padding-top: 0;
      }
    }

    .compareTableHeader th {
      font-weight: normal;
      font-size: 18px;
      padding-bottom: 10px;
      vertical-align: top;

      &.headerScore {
        text-align: right;
      }
      &.headerKey {
        @media screen and (max-width: 700px) {
          display: none;
        }
      }
      &.headerScoreFirst,
      &.headerScoreSelected {
        position: relative;
        padding-left: 20px;
        span {
          position: absolute;
          border-radius: 3px;
          width: 12px;
          height: 6px;
          top: 11px;
          left: 0;
        }
      }
      &.headerExpando {
        width: 40px;
      }

      @media screen and (max-width: 1400px) {
        font-size: 14px;

        &.headerScoreFirst,
        &.headerScore {
          display: none;
        }
      }
    }

    // data table
    td.rankColumn {
      font-family: Garamond Regular;
      font-size: 26px;
      color: $henley-gray;
      width: 70px;
      padding-right: 24px;
      @media screen and (max-width: 1400px) {
        font-size: 22px;
        width: 50px;
      }
      @media screen and (max-width: 700px) {
        font-size: 18px;
        width: 40px;
        padding-right: 12px;
        padding-top: 4px;
      }
    }

    td.citynameColumn {
      width: 210px;
      font-family: Garamond Regular;
      padding-right: 30px;
      font-size: 26px;
      @media screen and (max-width: 1400px) {
        font-size: 22px;
        width: 170px;
      }
      @media screen and (max-width: 700px) {
        display: none;
      }
    }

    td.barchartColumn {
      position: relative;
      padding-right: 30px;

      .barchartBackground,
      .barchartForeground {
        position: absolute;
        left: 0;
        top: 23px;
        height: 20px;
        border-radius: 10px;
        background: #fff;

        @media screen and (max-width: 700px) {
          height: 12px;
          top: 32px;
        }
      }
      .barchartBackground {
        width: calc(100% - 30px);
        @media screen and (max-width: 700px) {
          width: calc(100% - 10px);
        }
      }
      @keyframes slidein {
        from {
          max-width: 0px;
          opacity: 0;
        }
        to {
          max-width: 100%;
          opacity: 1;
        }
      }
      .barchartForeground {
        overflow: hidden;
        width: calc(100% - 30px);
        display: grid;
        .barchartElement {
          height: 20px;
          border: 1px solid #fff;
          border-radius: 20px;
          text-indent: -10000px;
          animation: slidein 300ms;
          @media screen and (max-width: 700px) {
            height: 12px;
          }
        }
      }
      .barChartLabel {
        font-size: 18px;
        font-family: Garamond Regular;
        margin-bottom: 24px;
        margin-top: 4px;
        @media screen and (min-width: 700px) {
          display: none;
        }
        @media screen and (max-width: 700px) {
          display: block;
        }
      }
      @media screen and (max-width: 700px) {
        padding-right: 10px;
      }
    }

    @keyframes appear {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    td.scoreFirstColumn,
    td.scoreSelectedColumn,
    td.scoreColumn {
      font-family: Garamond Regular;
      font-weight: 400;
      font-size: 26px;
      line-height: 26px;
      color: $henley-gray;
      width: 140px;
      padding: 20px 30px 20px 0;
      @media screen and (max-width: 1400px) {
        font-size: 22px;
        width: 90px;
      }
    }
    td.scoreFirstColumn,
    td.scoreColumn {
      @media screen and (max-width: 1400px) {
        display: none;
      }
    }

    td.scoreColumn {
      color: $henley-blue;
      text-align: right;
      padding-right: 0;
      width: 90px;
      animation: appear 1s;
      @media screen and (max-width: 1400px) {
        font-size: 22px;
        width: 50px;
        padding-left: 30px;
      }
      @media screen and (max-width: 700px) {
        font-size: 17px;
        line-height: 17px;
        width: 40px;
        padding: 10px 0 0 0;
      }
      .scoreColumnExpanded:first-child {
        padding-bottom: 16px;
        display: block;
      }
      .scoreColumnExpanded:not(:first-child) {
        width: 50px;
        display: block;
        font-family: Garamond Regular;
        font-size: 18px;
        color: $henley-gray;
        text-align: right;
        padding-top: 24px;
      }
    }

    td.expandoControl {
      vertical-align: top;
      padding-top: 16px;
      text-align: right;
      cursor: pointer;
      svg {
        width: 14px;
        height: 14px;
        pointer-events: none;
      }
      @media screen and (max-width: 1400px) {
        padding-top: 18px;
      }
      @media screen and (max-width: 700px) {
        padding-top: 4px;
      }
    }

    @media screen and (max-width: 700px) {
      .cityTableHeaderH3,
      .cityTableHeaderH2 {
        font-size: 17px;
      }
    }
  }

  .expandedCityInfo {
    width: 100%;
    margin: 50px 0 16px;
    tr {
      border: none;
    }
    .expandedCityInfoIcon {
      min-width: 32px;
      width: 32px;
      padding-right: 12px;
      padding-top: 10px;
    }
    .expandedCityInfoName {
      padding: 10px 0;
      white-space: nowrap;
    }
    .expandedCityInfoValue {
      width: 60px;
      padding: 10px 0;
      font-family: Garamond Regular;
      font-size: 18px;
      color: $henley-gray;
      text-align: right;
      @media screen and (max-width: 700px) {
        line-height: 20px;
      }
    }
    @media screen and (max-width: 700px) {
      margin-top: 20px;
      font-size: 14px;
    }
  }
}
