$henley-blue: #405363;
$henley-gray: #75767A;
$secondary-gray: #C1C5C8;
$light-gray: #C5C6C8;
$gray: #6F7072;
$dark-gray: #4B4B4D;
$background-colour: #f7f7f7;

[data-all-embed] {
	.embedH2 {
		margin-bottom: 50px;
	}
	.allEmbedContainer {
		display: flex;
		justify-content: space-between;
		width: 100%;
		@media screen and (max-width: 900px) {
		    display: block;
	  	}
	}

	.leftColumn {
		min-width: 240px;
		max-width: 30%;
		padding-right: 40px;
		flex-grow: 1;

		.mobileCollapsed {
			margin-top: 40px;
			&[data-collapsed=true] {
				@media screen and (max-width: 900px) {
					display: none;
				}
			}
		}
		
		.parameterSelection {
			background: #fff;
			padding: 20px;

			@media screen and (max-width: 700px) {
				margin-right: -20px;
			}

			h3 {
				position: relative;
				.mobileCollapsedControl {
					cursor: pointer;
					svg {
						position: absolute;
						right: 0;
						top: 6px;
						width: 14px;
						height: 14px;
					}
				}

				@media screen and (max-width: 900px) {
					margin-bottom: 0;
					padding-right: 30px;
				}
				@media screen and (max-width: 1200px) {
				    font-size: 22px;
			  	}
			}

			ul.selectionList {
				li {
					padding-left: 0px;
					&:before {
						border: none;
					}
					input.parameterCheckbox {
						opacity: 0;
						width: 36px;
					    height: 20px;
					    position: absolute;
					    left: 0;
					    top: 0;
					    z-index: 1;
					    cursor: pointer;
					}
					label {
						font-size: 18px;
						margin-left: 46px;
						font-weight: normal;
						font-family: 'Roboto Regular', Roboto;
						cursor: pointer;
						@media screen and (max-width: 1200px) {
						    font-size: 14px;
					  	}
					}
					.checkboxControl {
						width: 36px;
						height: 20px;
						border-radius: 10px;
						border-width: 2px;
						border-style: solid;
						position: absolute;
						left: 0;
						top: 0;
						svg {
							transform: scale(0.5) translate(0, -15px);
						}
					}
					input.parameterCheckbox:not(:checked) + .parameterControls > .checkboxControl {
						background: none;
					}
					input.parameterCheckbox[disabled] {
						cursor: default;
					}
					input.parameterCheckbox[disabled] + .parameterControls {
						& > label {
							color: $secondary-gray;
							cursor: default;
						}
					}
				}
			}
		}

		@media screen and (max-width: 900px) {
		    max-width: 100%;
		    padding: 0 0 40px;
	  	}

	  	.selectionButtons {
	  		.button,
	  		.buttonReset {
	  			padding: 12px 30px;
			    height: auto;
			    min-width: 197px;

	  			color: #fff;
    			background-color: $henley-blue;
    			border: 1px solid $henley-blue;
    			border-radius: 2px;

			    font-size: 10px;
			    font-weight: 700;
			    letter-spacing: .163em;
    			line-height: 1.4em;
    			text-transform: uppercase;
			    text-decoration: none;
    			text-align: center;
	  		}
	  		.buttonReset {
	  			text-align: center;
	  			color: $henley-blue;
    			background-color: #fff;
    			margin: 0 auto;
    			display: block;
	  		}
	  		.button + .buttonReset {
	  			margin-left: 20px;
	  			display: inline-block;
	  			@media screen and (max-width: 420px) {
	  				margin: 16px 0 0;
	  			}
	  		}

	  		@media screen and (max-width: 1000px) {
			    .button,
		  		.buttonReset {
		  			min-width: 100px;
		  		}
		  	}
	  		@media screen and (max-width: 600px) {
			    .button,
		  		.buttonReset {
		  			min-width: 100px;
		  		}
		  	}
		  	@media screen and (max-width: 420px) {
			    .button,
		  		.buttonReset {
		  			display: block;
		  		}
		  	}
	  	}

	}
	.rightColumn {
		flex-grow: 2;
	}

	.allTable {
		color: $henley-blue;
		width: 100%;

		tr {
			border-bottom: 1px solid $secondary-gray;
		}
		td {
			@media screen and (max-width: 1200px) {
				vertical-align: top;
    			padding-top: 16px;
		  	}
		  	@media screen and (max-width: 700px) {
				padding-top: 0;
		  	}
		}

		.allTableHeader th {
			font-weight: normal;
			font-size: 18px;
			padding-bottom: 10px;

			&.headerScore {
				text-align: right;
			}
			&.headerKey {
				@media screen and (max-width: 700px) {
				    display: none;
			  	}
			}
			&.headerExpando {
				width: 24px;
			}

			@media screen and (max-width: 1200px) {
			    font-size: 14px;
		  	}
		}

		// data table
		td.rankColumn {
			font-family: Garamond Regular;
			font-size: 26px;
			color: $henley-gray;
			width: 70px;
			padding-right: 24px;
			@media screen and (max-width: 1200px) {
			    font-size: 22px;
			    width: 50px;
		  	}
			@media screen and (max-width: 700px) {
				font-size: 18px;
			    width: 50px;
				padding-right: 12px;
				padding-top: 4px;
		  	}
		}

		td.citynameColumn {
			width: 210px;
			font-family: Garamond Regular;
			padding: 17px 30px 0 0;
			font-size: 26px;
			vertical-align: top;
			@media screen and (max-width: 1200px) {
			    font-size: 22px;
			    width: 170px;
		  	}
			@media screen and (max-width: 700px) {
			    display: none;
		  	}
		}

		td.barchartColumn {
			position: relative;
			padding-right: 30px;

			.barchartBackground,
			.barchartForeground {
				position: absolute;
				left: 0;
				top: 23px;
				height: 20px;
				border-radius: 10px;
				background: #fff;

				@media screen and (max-width: 700px) {
				    height: 12px;
				    top: 32px;
			  	}
			}
			.barchartBackground {
				width: calc(100% - 30px);
				@media screen and (max-width: 700px) {
				    width: calc(100% - 20px);
			  	}
			}
			.barchartForeground {
			    width: 100%;
			    display: grid;
			    margin-left: 10px;
			    .barchartElement {
			    	height: 20px;
			    	border: 1px solid #fff;
			    	border-radius: 20px;
			    	animation: slidein 300ms;
			    	margin-left: -10px;
			    	position: relative;
			    	@media screen and (max-width: 700px) {
					    height: 12px;
				  	}
			    }
			}
			.barChartLabel {
				font-size: 18px;
				font-family: Garamond Regular;
				margin-bottom: 24px;
				margin-top: 4px;
				@media screen and (min-width: 700px) {
				    display: none;
			  	}
				@media screen and (max-width: 700px) {
				    display: block;
			  	}
			}
			@keyframes slidein {
			  from {
			    max-width: 0px;
			    opacity: 0;
			  }
			  to {
			    max-width: 100%;
			    opacity: 1;
			  }
			}

			@media screen and (max-width: 700px) {
			    padding-right: 10px;
		  	}
		}

		@keyframes appear {
		  from { opacity: 0; }
		  to { opacity: 1; }
		}

		td.scoreColumn {
			width: 70px;
			color: $henley-gray;
			text-align: right;
			font-family: Garamond Regular;
    		font-weight: 400;
    		font-size: 26px;
    		line-height: 26px;
    		padding: 20px 0 20px 50px;
    		animation: appear 1s;
    		vertical-align: top;
    		@media screen and (max-width: 1200px) {
			    font-size: 22px;
			    width: 50px;
		  	}
		  	@media screen and (max-width: 900px) {
	    		.scoreColumnExpanded:first-child {
	    			display: block;
	    			padding-bottom: 12px;
				}
				.scoreColumnExpanded:not(:first-child) {
					padding: 22px 0 0;
				}
			}
		  	@media screen and (max-width: 700px) {
    			font-size: 14px;
    			line-height: 17px;
    			min-width: 40px;
    			padding: 12px 0 0;
    			.scoreColumnExpanded:first-child {
	    			padding-bottom: 14px;
				}
				.scoreColumnExpanded:not(:first-child) {
					padding: 24px 0 0;
				}
    		}
    		.scoreColumnExpanded:not(:first-child) {
				width: 50px;
				display: block;
				font-family: Garamond Regular;
				font-size: 18px;
				color: $henley-gray;
				text-align: right;
			}
		}

		td.expandoControl {
			vertical-align: top;
			padding-top: 16px;
			text-align: right;
			cursor: pointer;
			svg {
				width: 14px;
				height: 14px;
				pointer-events: none;
			}
			@media screen and (max-width: 1200px) {
				padding-top: 18px;
		  	}
		  	@media screen and (max-width: 700px) {
				padding-top: 4px;
		  	}
		}

		@media screen and (max-width: 700px) {
		    .cityTableHeaderH3,
			.cityTableHeaderH2,
			td.scoreColumn {
				font-size: 17px;
			}
	  	}
	}

	.expandedCityInfo {
		width: 100%;
		margin: 50px 0 16px -2px;
		tr {
			border: none;
		}
		.expandedCityInfoIcon {
			min-width: 40px;
			width: 40px;
			padding-right: 12px;
			padding-top: 10px;
		}
		.expandedCityInfoName {
			padding: 10px 0;
			white-space: nowrap;
		}
		@media screen and (max-width: 700px) {
			margin-top: 20px;
			font-size: 14px;
		}
	}

	.parameterHover {
		position: absolute;
	    top: -21px;
	    left: 50px;
	    width: 100%;
	    height: 100%;
	    z-index: 1000;
		pointer-events: none;
		transition: left 0.2s linear;
    	
    	.parameterHoverContainer {
			position: absolute;
			left: 0;
			top: 0;
			font-family: Garamond Regular;
			color: $henley-gray;
			font-size: 26px;
			line-height: 40px;
			width: auto;
			background: white;
			border: 1px solid $henley-blue;
			padding: 8px 40px 8px 10px;
			white-space: nowrap;
			&:before {
				content: " ";
				position: absolute;
				top: 19px;
				left: -11px;
				border-top: 11px solid transparent;
				border-left: none;
				border-right: 11px solid $henley-blue;
				border-bottom: 11px solid transparent;
			}
			&:after {
				content: " ";
				position: absolute;
				top: 20px;
				left: -10px;
				border-top: 10px solid transparent;
				border-left: none;
				border-right: 10px solid white;
				border-bottom: 10px solid transparent;
			}
			.parameterHoverContainerImage {
				img {
					margin: -4px 10px 0 5px;
					@media screen and (max-width: 1200px) {
						transform: scale(0.8);
					}
				}
			}
			.parameterHoverContainerName {
				padding: 0 20px 0 10px;
				font-family: Roboto Regular;
				font-size: 18px;
				@media screen and (max-width: 1200px) {
					font-size: 14px;
					line-height: 18px;
				}
			}
			.parameterHoverContainerValue {
				padding-right: 10px;
				color: $henley-blue;
			}
			@media screen and (max-width: 1200px) {
				font-size: 17px;
				line-height: 40px;
			}
		}
	}
}